<template>
  <div>
    <v-card elevation="4">
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-h5 font-weight-medium">Formas</div>
        <HelpDialog
          class="mx-4 flex-grow-1"
          title="Forma do Tratamento de Dados"
          message="Preciso de ajuda para cadastrar a forma de tratamento de dados."
        >
          <p>
            A LGPD descreve, de maneira subjetiva, que o Titular deve estar ciente da maneira que seus dados serão tratados.
          </p>
          <v-expansion-panels class="my-2" ripple>
            <v-expansion-panel>
              <v-expansion-panel-header color="grey lighten-2">
                Acesso fácil à Forma do Tratamento de Dados (LGPD Art. 9, inciso VI)
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <p>...</p>
                <p>
                  <b>Art. 9º:</b> O titular tem direito ao acesso facilitado às informações sobre o tratamento de seus dados, que deverão ser disponibilizadas de forma clara, adequada e ostensiva acerca de, entre outras características previstas em regulamentação para o atendimento do princípio do livre acesso:
                </p>
                <p>...</p>
                <p>
                  <b>II</b> - forma e duração do tratamento, observados os segredos comercial e industrial;
                </p>
                <p>...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </HelpDialog>
        <v-btn color="success" @click="openFormDialog()">
          Nova Forma
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="py-2">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details="auto"
          class="mt-0 pt-0"
        />
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="means"
        :items-per-page="-1"
        :search="search"
        :loading="loading"
        loading-text="Carregando... Por favor, espere"
        hide-default-footer
        no-data-text="Nenhuma Forma cadastrada"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="openFormDialog(item)">
            <v-icon class="mr-2"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="
              deleteDialog = true;
              form_id = item.id;
            "
          >
            <v-icon class="mr-2"> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <v-dialog v-model="formDialog" max-width="800" :persistent="loadingSave">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between">
          <div v-if="meansForm.id">Edição de Forma</div>
          <div v-else>Nova Forma</div>
          <div>
            <v-btn icon @click="formDialog = false">
              <v-icon color="black" >mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <v-form ref="form" @submit.prevent="saveMean()">
            <v-text-field
              v-model="meansForm.title"
              label="Titulo*"
              outlined
              hide-details="auto"
              color="black"
              background-color="white"
              :rules="[$rules.required]"
              :disabled="loadingSave"
            />
            <v-textarea
              v-model="meansForm.description"
              label="Descrição*"
              outlined
              hide-details="auto"
              color="black"
              class="my-3"
              background-color="white"
              :rules="[$rules.required]"
              :disabled="loadingSave"
            />
          <v-card-actions class="pt-3 pb-0 pr-0">
            <v-spacer/>
            <v-btn
              color="secondary"
              class="mr-1" text
              @click="formDialog = false"
              :disabled="loadingSave"
            >
              Cancelar
            </v-btn>
            <v-btn color="success" type="submit" :loading="loadingSave">
              <template v-if="meansForm.id">
                Salvar
              </template>
              <template v-else>
                Criar
              </template>
            </v-btn>
          </v-card-actions>
        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500"
      :persistent="loadingDelete"
    >
      <v-card color="grey lighten-2">
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir essa Forma?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            class="mr-1"
            text
            :disabled="loadingDelete"
            @click="deleteDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="ml-1"
            :loading="loadingDelete"
            color="error"
            text
            @click="deleteForm()"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HelpDialog from "@/components/HelpDialog.vue";

export default {
  name: "Means",

  components: { HelpDialog, },

  data: () => ({
    search: "",
    loading: false,
    loadingSave: false,
    loadingDelete: false,
    formDialog: false,
    deleteDialog: false,
    form_id: -1,
    meansForm: {
      title: "",
      description: "",
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "title",
      },
      { text: "Descrição", value: "description" },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    means: [],
  }),
  mounted() {
    this.getMeans();
  },
  methods: {
    saveMean() {
      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        let request;
        if (this.meansForm.id) {
          request = this.$axios.put(
            `/means/${this.meansForm.id}`,
            this.meansForm
          );
        } else {
          request = this.$axios.post("/means", this.meansForm);
        }
        request
          .then((response) => {
            this.$showMessage("success", "Forma Salva");
            this.getMeans();
            this.formDialog = false;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 404) {
                this.$showMessage("error", "Forma não encontrada");
                return;
              }
            }
            this.$showMessage("error", "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    deleteForm() {
      this.loadingDelete = true;
      this.$axios
        .delete(`/means/${this.form_id}`)
        .then((response) => {
          this.$showMessage("success", "Forma Excluida");
          this.getMeans();
          this.deleteDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 404) {
              this.$showMessage("error", "Forma não encontrada");
              return;
            }
          }
          this.$showMessage("error", "Ocorreu um erro inesperado");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    getMeans() {
      this.loading = true;
      this.$axios
        .get("/means")
        .then((response) => {
          this.means = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.$showMessage(
                "warning",
                "Não foi possível carregar as Formas"
              );
            }
          }
          this.$showMessage(
            "error",
            "Erro inesperado no carregamento das Formas"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /* resetForm(){
      this.$refs.form.reset()
    }, */
    openFormDialog(means) {
      if (means) {
        this.meansForm = Object.assign({}, means);
      } else {
        this.meansForm = {};
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
      this.formDialog = true; 
    }
  },
};
</script>
